<template>
  <div>
    <topbar
      :titulo="$t('CONTABIL.RELATORIO_RAZAO')"
      :breadcrumbs="breadcrumbs"
      :subtitulo="$t('CONTABIL.RELATORIO_RAZAO_DESCRICAO')"
    />

    <filtro-razao
      @atualizar-filtro="atualizarFiltro"
      @limpar="limpar"
      @filtrar="filtrar"
      @refs="capturarRefs"
    />

    <b-row>
      <b-col md="auto">
        <b-btn
          variant="info"
          :disabled="btn"
          class="botao-acao-filtro mb-3 w-100"
          @click="gerarRelatorio"
          >{{ $t('GERAL.GERAR_RELATORIO') }}</b-btn
        >
      </b-col>

      <b-col md="auto">
        <b-btn
          :disabled="btn"
          variant="info"
          class="botao-acao-filtro mb-3 w-100"
          @click="openModal('modalRazao')"
          >Visualizar totais</b-btn
        >
      </b-col>
    </b-row>

    <tabela-razao :itens="relatorioRazao" />

    <paginacao v-model="paginacao" :total="paginacao.total" @input="filtrar" />

    <modal-razao
      :exibir="modais.modalRazao"
      :itens="periodoContabilRazaoResponse"
      @fechar="closeModal('modalRazao')"
    />
  </div>
</template>

<script>
//COMPONENTS
import Paginacao from '@/components/paginacao/Paginacao';
import FiltroRazao from '@/views/contabil/relatorios/relatorio-razao/components/FiltroRazao';
import TabelaRazao from '@/views/contabil/relatorios/relatorio-razao/components/TabelaRazao';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import ModalRazao from './components/Modal';

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';

//SERVICES
import RazaoService from '@/common/services/contabil/razao.service';

export default {
  name: 'ContabilRelatorioRazao',
  components: {
    Paginacao,
    FiltroRazao,
    TabelaRazao,
    Topbar,
    ModalRazao,
  },
  data() {
    return {
      refs: '',
      btn: true,
      periodoContabilRazaoResponse: [],
      modais: {
        modalRazao: false,
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
      relatorioRazao: [],
      breadcrumbs: [
        { titulo: this.$t('CONTABIL.CONTABIL') },
        { titulo: this.$t('CONTABIL.RELATORIOS') },
        { titulo: this.$t('CONTABIL.RELATORIO_RAZAO') },
      ],
      filtro: {
        dataInicio: '',
        dataFim: '',
        planoContaId: '',
        detalhar: false,
        ocultarEncerramentoExercicio: false,
      },
    };
  },
  methods: {
    capturarRefs(refs) {
      this.$refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    openModal(key) {
      return modais.abrirModal(this, key);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
    atualizarFiltro(filtro) {
      this.filtro = filtro;
    },
    gerarRelatorio() {
      this.$store.dispatch(START_LOADING);
      RazaoService.gerarRelatorioRazao(this.filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);
            let blob = new Blob([bytes], {
              type: 'application/pdf',
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    filtrar() {
      if (!this.validarFormulario()) return;

      this.$store.dispatch(START_LOADING);
      RazaoService.obterRazaoPaginado(this.filtro, this.paginacao)
        .then(({ data }) => {
          this.periodoContabilRazaoResponse = data.periodoContabilRazaoResponse;
          this.relatorioRazao = data.resultPaginado.itens;
          this.paginacao.total = data.resultPaginado.paginacao.totalDeElementos;

          if (data.resultPaginado.itens.length > 0) {
            this.btn = false;
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limpar() {
      this.paginacao = {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      };
      this.filtro = {
        dataInicio: '',
        dataFim: '',
        planoContaId: '',
        detalhar: false,
        ocultarEncerramentoExercicio: false,
      };
      this.relatorioRazao = [];
      this.btn = true;
    },
  },
};
</script>
