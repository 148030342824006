<template>
  <b-row>
    <b-col md="2">
      <input-date-only-btn
        ref="dataInicio"
        v-model="filtro.dataInicio"
        :label="$t('FINANCEIRO.PERIODO_INICIAL')"
        required
      />
    </b-col>

    <b-col md="2">
      <input-date-only-btn
        ref="dataFim"
        v-model="filtro.dataFim"
        :label="$t('FINANCEIRO.PERIODO_FINAL')"
        required
      />
    </b-col>

    <b-col md="4">
      <input-select-search
        ref="planoContaId"
        v-model="filtro.planoContaId"
        :label="$t('CONTABIL.CONTA_CONTABIL')"
        :options="opcoes.planosConta"
      />
    </b-col>

    <b-col cols="12" md="auto">
      <input-checkbox
        class="botao-acao-filtro pt-2 mb-md-3 w-100"
        v-model="filtro.ocultarEncerramentoExercicio"
        type="boolean"
        :label="$t('CONTABIL.OCULTAR_ENCERRAMENTO')"
        :placeholder="$t('CONTABIL.OCULTAR_ENCERRAMENTO')"
      />
    </b-col>

    <b-col cols="12" md="auto">
      <input-checkbox
        class="botao-acao-filtro pt-2 mb-md-3 w-100"
        v-model="filtro.detalhar"
        type="boolean"
        :label="$t('CONTABIL.DETALHAR')"
        :placeholder="$t('CONTABIL.DETALHAR')"
      />
    </b-col>

    <b-col cols="12" md="auto">
      <b-btn
        class="botao-acao-filtro mb-3 w-100"
        variant="primary"
        @click="filtrar"
        >{{ $t('GERAL.FILTRAR') }}
      </b-btn>
    </b-col>

    <b-col cols="12" md="auto">
      <b-btn
        class="botao-acao-filtro mb-3 w-100"
        variant="secondary"
        @click="limpar"
      >
        {{ $t('GERAL.LIMPAR') }}
      </b-btn>
    </b-col>
  </b-row>
</template>

<script>
// Aux & helpes
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

//servives
import PlanoContaService from '@/common/services/plano-conta/plano-conta.service.js';

// Components:
import {
  InputDateOnlyBtn,
  InputSelectSearch,
  InputCheckbox,
} from '@/components/inputs';

export default {
  name: 'FiltroRazao',

  props: {
    form: { type: Object, default: Object },
  },

  components: {
    InputDateOnlyBtn,
    InputSelectSearch,
    InputCheckbox,
  },
  data() {
    return {
      filtro: {
        dataInicio: '',
        dataFim: '',
        planoContaId: '',
        detalhar: false,
        ocultarEncerramentoExercicio: false,
      },
      opcoes: {
        planosConta: [],
      },
    };
  },
  mounted() {
    this.getPlanoContas();
    this.refs();
  },
  watch: {
    filtro: {
      handler(value) {
        this.$emit('atualizar-filtro', value);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    refs() {
      this.$emit('refs', this.$refs);
    },

    getPlanoContas() {
      this.$store.dispatch(START_LOADING);
      PlanoContaService.obterTodos()
        .then(({ data }) => {
          data = data.map((row) => ({
            text: `${row.codigo} - ${row.descricao} `,
            value: row.id,
          }));
          this.opcoes.planosConta = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇÕES FILTRO
    filtrar() {
      this.$emit('filtrar');
    },
    limpar() {
      this.filtro = {
        dataInicio: '',
        dataFim: '',
        planoContaId: '',
        detalhar: false,
        ocultarEncerramentoExercicio: false,
      };
      this.$emit('limpar');
    },
  },
};
</script>
