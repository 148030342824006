<template>
  <modal
    id="modalRazao"
    titulo="Totais razão"
    :exibir="exibir"
    @fechar="fecharModal"
    :campos="$refs"
    :tamanho="'xl'"
    :ocultarRodape="true"
  >
    <tabela-totais-razao :itens="itens" />

    <template #modal-footer> </template>
  </modal>
</template>
<script>
// Utils & Aux:

// Components:
import Modal from '@/components/modal/Modal';
import TabelaTotaisRazao from '@/views/contabil/relatorios/relatorio-razao/components/TabelaTotaisRazao';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    itens: { type: Array, default: Array },
  },
  components: {
    Modal,
    TabelaTotaisRazao,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
