<template>
  <div class="mt-4 limit-overflow">
    <b-table
      :fields="fields"
      :items="itens"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      show-empty
      responsive
      striped
      hover
    />
  </div>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

export default {
  name: 'TabelaRazao',
  props: {
    itens: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'planoContaDescricaoCompleta',
          label: this.$t('CONTABIL.DESCRICAO'),
          sortable: true,
        },
        {
          key: 'valorSaldoAnterior',
          label: this.$t('CONTABIL.SALDO_ANTERIOR'),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatacaoMoedaBRL(value);
          },
        },
        {
          key: 'valorSaldoTotal',
          label: this.$t('CONTABIL.SALDO_TOTAL'),
          sortable: true,
          formatter: (value) => {
            if (value) return helpers.formatacaoMoedaBRL(value);
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
div {
  font-size: 12px;
}
</style>
