import ApiService from '@/common/api/api.service';

let apiBasePath = 'PeriodoContabil';
const RazaoService = {
    // FUNÇÕES DE BUSCA:
    async obterRazaoPaginado(filtro, paginacao) {
        let { data } = await ApiService.get(`${apiBasePath}/listar-por-paginacao-razao`, {
            params: {
                ...filtro,
                ...paginacao
            }
        });
        return data;
    },

    // Relatorios
    async gerarRelatorioRazao(filtro) {
        let { data } = await ApiService.get(`${apiBasePath}/relatorio-contabil/razao`, {
            params: {
                ...filtro,
            }
        });
        return data;
    },

};

export default RazaoService;
